'use client'

import { NotFoundRedirect } from './Components/NotFoundRedirect'

export const metadata = {
  title: 'Not Found',
  description: 'Generated by Next.js',
}

export default function NotFound() {
  return (
    <html lang="en">
      <body>
        <NotFoundRedirect />
      </body>
    </html>
  )
}
